import React, { useState, useEffect } from "react";
import Header from "./Header";
import SideMenu from "./SideMenu";
import { useLocation } from "react-router-dom";
import * as XLSX from 'xlsx';
import { PDFDocument } from 'pdf-lib';
import Modal from "react-modal";
import './App.css';

function ReporteVenta() {
  const location = useLocation();
  const [dataLogin, setDataLogin] = useState([]);
  const [ventas, setVentas] = useState([]);
  const [maquinariasOptions, setMaquinariasOptions] = useState([]);
  const [clientesOptions, setClientesOptions] = useState([]);
  const [equiposOptions, setEquiposOptions] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  useEffect(() => {
    if (location.state) {
      const receivedData = location.state;
      setDataLogin(receivedData);
      obtenerMaquinarias();
      obtenerClientes();
    }
  }, [location]);  
  
  const formatearRut = (rut) => {
    const rutFormateado = `${rut.slice(0, 2)}.${rut.slice(2, 5)}.${rut.slice(5)}`;
    return rutFormateado;
  };

  const obtenerMaquinarias = async () => {
    try {
      const response = await fetch('https://api.vittamaq.cl/maquinaria.php');
      const data = await response.json();

      if(data && data[0].id_maquinaria){
        setMaquinariasOptions(data);
      }
    } catch (error) {
      setMaquinariasOptions([]);
    }
  };

  const obtenerClientes = async () => {
    try {
      const response = await fetch('https://api.vittamaq.cl/cliente.php');
      const data = await response.json();

      if(data && data[0].id_cliente){
        setClientesOptions(data);
      }
    } catch (error) {
      setClientesOptions([]);
    }
  };

  const obtenerEquipos = async (maquinaria) => {
    try {
      const response = await fetch(
        `https://api.vittamaq.cl/equipo.php?id_maquinaria=${maquinaria}`
      );
      const data = await response.json();

      if(data && data[0].id_equipo){
        setEquiposOptions(data);
      }
    } catch (error) {
      setEquiposOptions([]);
    }
  };

  const [clienteSelected, setClienteSelected] = useState('');
  const [obras, setObras] = useState([]);

  const handleClienteSelect = async (clienteSelected) => {
    setClienteSelected(clienteSelected);
    await obtenerObras(clienteSelected);

    setFormConsultaData({
      ...formConsultaData,
      ["id_cliente"]: clienteSelected,
    });

    //console.log(formConsultaData);
  };
  
  const obtenerObras = async (cliente) => {
    try {
      const response = await fetch(
        `https://api.vittamaq.cl/reporte_estado_pago.php?id_cliente=${cliente}`
      );
      const data = await response.json();
      if (data && data[0].id_obra) {
        setObras(data);
      }
    } catch (error) {
      setObras([]);
    }
  };

  //Filtro
  const [filtroVentas, setFiltroVentas] = useState('');
  const manejarCambioFiltro = (event) => {
    setFiltroVentas(event.target.value);
  };

  const ventasFiltradas = ventas.filter((item) =>
    item.folio.toLowerCase().includes(filtroVentas.toLowerCase())
  );

  const currentDate = new Date().toISOString().split('T')[0];
  const [formConsultaData, setFormConsultaData] = useState({
    id_maquinaria: 0,
    id_cliente: 0,
    id_obra: 0,
    fecha_inicio: currentDate,
    fecha_fin: currentDate,
    equipos: 0,
  });

  const [maquinariaSelect, setMaquinariaSelect] = useState('');

  const handleFormConsultaData = async (e) => {
    const { name, value } = e.target;

    setFormConsultaData({
      ...formConsultaData,
      [name]: value,
    });
  };

  const handleMaquinariaSelect = async (maquinariaSelect) => {
    setMaquinariaSelect(clienteSelected);
    await obtenerEquipos(maquinariaSelect);

    setFormConsultaData({
      ...formConsultaData,
      ["id_maquinaria"]: maquinariaSelect,
    });

    //console.log(formConsultaData);
  };

  const handleBuscarEstadoPagoClick = async (event) => {
    event.preventDefault();

    try {
      const responseRequerimiento = await fetch(
        `https://api.vittamaq.cl/reporte_venta.php`,
        {
          method: "POST",
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json', 
          },
          body: JSON.stringify(formConsultaData),
        }
      );

      const dataRequerimiento = await responseRequerimiento.json();

      if (dataRequerimiento && dataRequerimiento[0].folio) {
        setVentas(dataRequerimiento);
      }

    } catch (error) {
      //console.log(error);
      setVentas([]);
    }
  }

  const exportToExcel = () => {
    const ahora = new Date();
    const fechaFormateada = formatearFecha(ahora);

    const arregloExportar = ventas.map(obj => {
      return {
        ["Maquinaria"]: obj.nombre_maquinaria,
        ["Equipo"]: obj.nombre_equipo,
        ["Folio"]: obj.folio,
        ["Estado"]: obj.estado,
        ["Fecha"]: obj.fecha_cotizacion,
        ["Nombre Cliente"]: obj.nombre_cliente,
        ["Nombre Obra"]: obj.nombre_obra
      };
    });

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(arregloExportar);
    XLSX.utils.book_append_sheet(wb, ws, 'Reporte de venta');
    XLSX.writeFile(wb, 'Ventas ' + fechaFormateada + '.xlsx');
  };

  const formatearFecha = (fecha) => {
    const dia = String(fecha.getDate()).padStart(2, '0');
    const mes = String(fecha.getMonth() + 1).padStart(2, '0');
    const year = fecha.getFullYear();
    const hora = String(fecha.getHours()).padStart(2, '0');
    const minutos = String(fecha.getMinutes()).padStart(2, '0');

    return `${dia}-${mes}-${year} ${hora}_${minutos}`;
  };
  

  /* Modals */
  const closeModal = () => {
    setModalOpen(false);
    setModalContent(null);
  };  

  // Ver Cotización PDF
  const handleModalCotizacion = async (folioCotizacion) => {
    let cabeceraCotizacion = [];
    let detalleCotizacion = [];

    cabeceraCotizacion = await obtenerCabeceraCotizacion(folioCotizacion);
    detalleCotizacion = await obtenerDetalleCotizacion(folioCotizacion);

    setModalContent(<CotizacionModalContent cabecera_cotizacion={cabeceraCotizacion} detalle_cotizacion={detalleCotizacion} />);
    setModalOpen(true);
  };

  const obtenerCabeceraCotizacion = async (folio) => {
    try {
      const response = await fetch(
        `https://api.vittamaq.cl/cabecera_cotizacion.php?folio=${folio}`
      );
      const data = await response.json();
      return data && data[0]?.folio ? data : [];
    } catch (error) {
      console.error("Error al obtener la cabecera de la cotización:", error);
      return [];
    }
  };

  const obtenerDetalleCotizacion = async (folio) => {
    try {
      const response = await fetch(
        `https://api.vittamaq.cl/detalle_cotizacion.php?folio=${folio}`
      );
      const data = await response.json();
      return data && data[0]?.folio ? data : [];
    } catch (error) {
      console.error("Error al obtener el detalle de la cotización:", error);
      return [];
    }
  };

  function CotizacionModalContent ({ cabecera_cotizacion, detalle_cotizacion }) {
    const [pdfBase64, setPdfBase64] = useState('');

    useEffect(() => {
      const generatePDF = async () => {
        try {
          const existingPdfBytes = await fetch('https://api.vittamaq.cl/obtienePlantillaCotizacion.php').then((res) => res.arrayBuffer());
          const pdfDoc = await PDFDocument.load(existingPdfBytes);
      
          // Obtener el formulario del PDF
          const form = pdfDoc.getForm();
      
          // Cabecera Cotización
          form.getTextField('Folio').setText(cabecera_cotizacion[0].folio);
          form.getTextField('Nombre_Cliente').setText(cabecera_cotizacion[0].nombre_cliente);
          form.getTextField('Rut_Cliente').setText(formatearRut(cabecera_cotizacion[0].rut_cliente));
          form.getTextField('Razon_Social').setText(cabecera_cotizacion[0].razon_social);
          form.getTextField('Giro_Cliente').setText(cabecera_cotizacion[0].giro);
          form.getTextField('Direccion_Cliente').setText(cabecera_cotizacion[0].direccion);
          form.getTextField('Obra_Cliente').setText(cabecera_cotizacion[0].nombre_obra);
          form.getTextField('Direccion_Obra').setText(cabecera_cotizacion[0].direccion_obra + " (" + cabecera_cotizacion[0].comuna_obra + ")");

          form.getTextField('Fecha_Cotizacion').setText(cabecera_cotizacion[0].fecha_cotizacion);
          form.getTextField('Contacto').setText(cabecera_cotizacion[0].nombre_contacto);
          form.getTextField('Cargo_Contacto').setText(cabecera_cotizacion[0].cargo_contacto);
          form.getTextField('Telefono_Contacto').setText(cabecera_cotizacion[0].telefono_contacto);
          form.getTextField('Email_Contacto').setText(cabecera_cotizacion[0].email_contacto);
          form.getTextField('Observaciones').setText(cabecera_cotizacion[0].observaciones ? cabecera_cotizacion[0].observaciones : "");

          if (cabecera_cotizacion[0].id_maquinaria === 1) {
            form.getTextField('Equipo').setText(
              `${cabecera_cotizacion[0].nombre_maquinaria} (${cabecera_cotizacion[0].nombre_requerimiento})`
            );
          } else {
            form.getTextField('Equipo').setText(
              `${cabecera_cotizacion[0].nombre_maquinaria} - ${cabecera_cotizacion[0].equipo} (${cabecera_cotizacion[0].nombre_requerimiento})`
            );
          }

          let pmInserted = false;
          // Servicios Cotización
          detalle_cotizacion.forEach((detalle, index) => {
            form.getTextField(`Item_Orden_${index + 1}`).setText(detalle.item_orden.split('-')[1]);
            if(detalle.descripcion !== "") {
              form.getTextField(`Descripcion_${index + 1}`).setText(detalle.descripcion);
            } else {
              form.getTextField(`Descripcion_${index + 1}`).setText(detalle.nombre_maquinaria + " " + detalle.tipo_maquinaria + " " + detalle.tipo_arriendo);
            }
            form.getTextField(`Cantidad_Minima_${index + 1}`).setText(detalle.cantidad_minima);
            form.getTextField(`Unidad_Medida_${index + 1}`).setText(detalle.unidad_medida);

            if (detalle.id_maquinaria === 1) {
              form.getTextField(`Valor_Unitario_Neto_${index + 1}`).setText(detalle.valor_unitario_neto + " UF");
            } else if (detalle.id_maquinaria === 2) {
              form.getTextField(`Valor_Unitario_Neto_${index + 1}`).setText(String("$ " + parseInt(detalle.valor_unitario_neto).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.')));
            } else {
              form.getTextField(`Valor_Unitario_Neto_${index + 1}`).setText(String(parseFloat(detalle.valor_unitario_neto).toFixed(2) + " UF"));
            }

            if (detalle.id_maquinaria === 1 && !pmInserted) {
              form.getTextField(`Valor_Unitario_Neto_PM_${index + 1}`).setText(detalle.valor_unitario_neto_pm + " UF (PM)");
              pmInserted = true;
            }
          });

          const modifiedPdfBytes = await pdfDoc.save();
  
          const arrayBufferToBase64 = (buffer) => {
            let binary = '';
            const bytes = new Uint8Array(buffer);
            const len = bytes.byteLength;
            for (let i = 0; i < len; i++) {
              binary += String.fromCharCode(bytes[i]);
            }
            return window.btoa(binary);
          };
  
          const pdfBase64 = arrayBufferToBase64(modifiedPdfBytes);
          setPdfBase64(`data:application/pdf;base64,${pdfBase64}`);
        } catch (error) {
          console.error('Error generating PDF:', error);
        }
      };
  
      generatePDF();
    }, [cabecera_cotizacion, detalle_cotizacion]);

    return (
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="mb-0">Cotización</h4>
            <button onClick={closeModal} className="btn-close"></button>
          </div>
          <div className="modal-body pt-0">
            <iframe
              src={pdfBase64}
              title="Estado de Pago"
              style={{ width: "100%", height: "700px", border: "none" }}
            />
          </div>
        </div>
      </div>
    );
  }


  return (
    <div className="d-flex" style={styles.mainContainer}>
      <SideMenu dataLogin={dataLogin} />
      <div className="w-100">
        <Header dataLogin={dataLogin} />
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <div className="d-flex gap-md-2 mb-3">
                <div>
                  <label className="form-label">Maquinaria</label>
                  <select className="form-select" name="tipo_estado_pago" value={formConsultaData.id_maquinaria} onChange={(e) => handleMaquinariaSelect(e.target.value)}>
                    <option value="0">Todos</option>
                    {maquinariasOptions.map((maquinaria) => (
                      <option key={maquinaria.id_maquinaria} value={maquinaria.id_maquinaria}>{maquinaria.maquinaria}</option>
                    ))}
                  </select>
                </div>
                <div>
                  <label className="form-label">Equipo</label>
                  {equiposOptions.length > 0 ? (
                  <select className="form-select" name="equipos" value={formConsultaData.equipos} onChange={handleFormConsultaData}>
                    <option value="0">Todos</option>
                    {equiposOptions.map((equipo) => (
                      <option key={equipo.id_equipo} value={equipo.id_equipo}>{equipo.marca + " " + equipo.modelo + " (" + equipo.numero_serie + ")"}</option>
                    ))}
                  </select>
                  ) : (
                    <select className="form-select" name="equipos">
                      <option value="0">Todos</option>
                    </select>
                    )}
                </div>
                <div>
                  <label className="form-label">Cliente</label>
                  <select className="form-select" name="cliente" value={clienteSelected} onChange={(e) => handleClienteSelect(e.target.value)}>
                    <option value="0">Todos</option>
                    {clientesOptions.map((cliente) => (
                      <option key={cliente.id_cliente} value={cliente.id_cliente}>{cliente.nombre_cliente}</option>
                    ))}
                  </select>
                </div>
                <div>
                  <label className="form-label">Obra</label>
                  {obras.length > 0 ? (
                    <select className="form-select" name="id_obra" value={formConsultaData.id_obra} onChange={handleFormConsultaData}>
                      <option value="0">Todos</option>
                      {obras.map((obra) => (
                        <option key={obra.id_obra} value={obra.id_obra}>{obra.nombre}</option>
                      ))}
                    </select>
                  ) : (
                    <select className="form-select" name="id_obra">
                      <option value="0">Todos</option>
                    </select>
                  )}
                </div>  
                <div>
                  <label className="form-label">Fecha Inicio</label>
                  <input
                    className="form-control"
                    type="date"
                    name="fecha_inicio"
                    value={formConsultaData.fecha_inicio}
                    onChange={handleFormConsultaData}
                  />
                </div>
                <div className="">
                  <label className="form-label">Fecha Fin</label>
                  <input
                    className="form-control"
                    type="date"
                    name="fecha_fin"
                    value={formConsultaData.fecha_fin}
                    onChange={handleFormConsultaData}
                  />
                </div>     
                <div>
                  <label className="form-label d-block">&nbsp; </label>
                  <button className="btn btn-yellow" onClick={handleBuscarEstadoPagoClick}>
                    <i className="bi bi-search"></i> Buscar
                  </button>
                </div>   
              </div>
              <div className="card">
                <div className="card-header d-flex justify-content-between align-items-center border-bottom-0 p-3 bg-white">
                  <h4>Reporte Venta</h4>
                  <div className="d-flex gap-md-2">
                    <div className="input-group w-auto">
                      <span className="input-group-text" id="search-folio">
                        <i className="bi bi-search"></i>
                      </span>
                      <input type="text" className="form-control" placeholder="Folio..." aria-describedby="search-folio" onChange={manejarCambioFiltro}/>
                    </div>
                    {ventas.length > 0 ? (
                      <button className="btn btn-success" onClick={exportToExcel}><i className="bi bi-download"></i> Exportar Excel</button>
                    ) : (
                      <button className="btn btn-success" disabled><i className="bi bi-download"></i> Exportar Excel</button>
                    )}
                  </div>
                </div>
                <div className="card-body card-body-filter p-0">
                  <div className="table-responsive mytable-container">
                    <table className="table table-hover">
                      <thead>
                        <tr className="table-active border-top border-bottom">
                          <th scope="col" className="ps-3">Maquinaria</th>
                          <th scope="col">Equipo</th>
                          <th scope="col">Folio</th>
                          <th scope="col">Estado</th>
                          <th scope="col">Fecha</th>
                          <th scope="col">Cliente</th>
                          <th scope="col" className="pe-3">Obra</th>
                        </tr>
                      </thead>
                      <tbody>
                        {ventasFiltradas.length > 0 ? (
                          ventasFiltradas.map((venta, index) => (
                            <tr key={index}>
                              <td className="ps-3">{venta.nombre_maquinaria}</td>
                              <td>{venta.marca + " " + venta.modelo}</td>
                              <td>
                                <button className="btn btn-link p-0" onClick={() => handleModalCotizacion(venta.folio)}>{venta.folio}</button>
                              </td>
                              <td>{venta.estado}</td>
                              <td>{venta.fecha_cotizacion}</td>
                              <td>{venta.nombre_cliente}</td>
                              <td className="pe-3">{venta.nombre_obra}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td className="text-center" colSpan={9}>No se encontraron ventas</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        style={modalStyles}
        contentLabel="Modal"
        className="modal"
      >
        {modalContent}
      </Modal>      
    </div>
  );
}
const modalStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
  },
};
const styles = {
  mainContainer: {
    height: "100vh",
  },
};

export default ReporteVenta;
