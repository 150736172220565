import React, { useState, useEffect } from "react";
import Header from "./Header";
import SideMenu from "./SideMenu";
import Modal from "react-modal";
import { useLocation } from "react-router-dom";
import * as XLSX from 'xlsx';
import { PDFDocument } from 'pdf-lib';
import './App.css';

function ReporteVenta() {
  const location = useLocation();
  const [dataLogin, setDataLogin] = useState([]);
  const [facturaciones, setFacturaciones] = useState([]);
  const [maquinariasOptions, setMaquinariasOptions] = useState([]);
  const [clientesOptions, setClientesOptions] = useState([]);
  const [equiposOptions, setEquiposOptions] = useState([]);
  const [hormigonerasOptions, setHormigonerasOptions] = useState([]);
  const [opcionesHormigonera, setOpcionesHormigonera] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [UFmensual, setUFmensual] = useState(null);
  const [indicadorUF, setIndicadorUF] = useState(null);

  useEffect(() => {
    if (location.state) {
      const receivedData = location.state;
      setDataLogin(receivedData);

      obtenerIndicadorUF();
      obtenerMaquinarias();
      obtenerClientes();
      obtenerHormigoneras();
    }
  }, [location]);

  const obtenerIndicadorUF = async () => {
    try {
      const response = await fetch('https://mindicador.cl/api/uf');
      const data = await response.json();
      
      if (data && data.serie && data.serie.length > 0) {
        const { serie } = data;

        setUFmensual(data);
  
        const fechaActual = new Date().toLocaleDateString('es-CL');
  
        const fechaActualIndicadorUF = serie.find(item => {
          const itemDate = new Date(item.fecha).toLocaleDateString('es-CL');
          return itemDate === fechaActual;
        });
  
        if (fechaActualIndicadorUF) {
          //console.log("Valor para la fecha actual:", fechaActualIndicadorUF.valor);
          setIndicadorUF(fechaActualIndicadorUF.valor);
        } else {
          //console.log("No se encontró el valor para la fecha actual.");
          setIndicadorUF(null);
        }
      } else {
        //console.log("No se encontraron datos válidos.");
        setIndicadorUF(null);
      }
    } catch (error) {
      //console.error("Error al obtener el indicador UF:", error);
      setIndicadorUF(null);
    }
  };
  
  let opcionesHormigoneraarray = [];
  const obtenerHormigoneras = async () => {
    try {
      const response = await fetch('https://api.vittamaq.cl/hormigonera.php');
      const data = await response.json();
      
      if(data && data[0].nombre_homigonera){
        // console.log(JSON.stringify(data,null,2));
        opcionesHormigoneraarray = data.map((hormigonera, index) => ({
          id: index + 1, // Generar un ID autogenerado basado en el índice (comenzando desde 1)
          nombre: hormigonera.nombre_homigonera // Mantener el nombre de la hormigonera
        }));
        setOpcionesHormigonera(opcionesHormigoneraarray);
        // console.log("opcionesHormigoneraarray  "+JSON.stringify(opcionesHormigoneraarray,null,2));
        setHormigonerasOptions(data);
      }
    } catch (error) {
      setHormigonerasOptions([]);
    }
  };

  const obtenerMaquinarias = async () => {
    try {
      const response = await fetch('https://api.vittamaq.cl/maquinaria.php');
      const data = await response.json();

      if(data && data[0].id_maquinaria){
        
        /* const maquinariaBH = data.find(maquinaria => maquinaria.id_maquinaria == "1");
        if (maquinariaBH) {
          setMaquinariasOptions([maquinariaBH]);
        } */
        setMaquinariasOptions(data);
      }
    } catch (error) {
      setMaquinariasOptions([]);
    }
  };

  const obtenerClientes = async () => {
    try {
      const response = await fetch('https://api.vittamaq.cl/cliente.php');
      const data = await response.json();

      if(data && data[0].id_cliente){
        // console.log(data);
        setClientesOptions(data);
      }
    } catch (error) {
      setClientesOptions([]);
    }
  };

  const obtenerEquipos = async (maquinaria) => {
    try {
      const response = await fetch(
        `https://api.vittamaq.cl/equipo.php?id_maquinaria=${maquinaria}`
      );
      const data = await response.json();
      
      if(data && data[0].id_equipo){
        //const equiposBH = data.filter(equipo => ["Telescópica", "Estacionaria"].includes(equipo.descripcion));
        // console.log(equiposBH);
        /* if (equiposBH) {
          setEquiposOptions(equiposBH);
        } */
        // console.log(data);
        setEquiposOptions(data);
      }
    } catch (error) {
      setEquiposOptions([]);
    }
  };

  const [clienteSelected, setClienteSelected] = useState('');
  const [obras, setObras] = useState([]);

  const handleClienteSelect = async (clienteSelected) => {
    setClienteSelected(clienteSelected);
    await obtenerObras(clienteSelected);

    setFormConsultaData({
      ...formConsultaData,
      ["id_cliente"]: clienteSelected,
    });

    //console.log(formConsultaData);
  };
  
  const obtenerObras = async (cliente) => {
    try {
      const response = await fetch(
        `https://api.vittamaq.cl/reporte_estado_pago.php?id_cliente=${cliente}`
      );
      const data = await response.json();
      if (data && data[0].id_obra) {
        setObras(data);
      }
    } catch (error) {
      setObras([]);
    }
  };

  //Filtro 
  const [filtroFacturaciones, setFiltroEstadoPagoBH] = useState('');
  const manejarCambioFiltro = (event) => {
    setFiltroEstadoPagoBH(event.target.value);
  };

  const facturacionesFiltradas = facturaciones.filter((item) =>
    item.nombre_obra.toLowerCase().includes(filtroFacturaciones.toLowerCase())
  );

  const currentDate = new Date().toISOString().split('T')[0];
  const [formConsultaData, setFormConsultaData] = useState({
    id_maquinaria: 0,
    id_cliente: 0,
    id_obra: 0,
    fecha_inicio: currentDate,
    fecha_fin: currentDate,
    equipos: 0,
    hormigonera:""
  });

  const [maquinariaSelect, setMaquinariaSelect] = useState('');
  const [hormigoneraSelect, setHormigoneraSelect] = useState('');

  const handleFormConsultaData = async (e) => {
    const { name, value } = e.target;

    setFormConsultaData({
      ...formConsultaData,
      [name]: value,
    });
  };
  /* useEffect(() => {
    console.log(JSON.stringify(formConsultaData,null,2));
  }, [formConsultaData]); */

  const handleMaquinariaSelect = async (maquinariaSelect) => {
    setMaquinariaSelect(maquinariaSelect);
    await obtenerEquipos(maquinariaSelect);

    setFormConsultaData({
      ...formConsultaData,
      ["id_maquinaria"]: maquinariaSelect,
    });

    //console.log(formConsultaData);
  };

  const handleHormigoneraSelect = async (hormigoneraSelect) => {
    setHormigoneraSelect(hormigoneraSelect);
    const hormigoneraSeleccionada = opcionesHormigonera.find(option => option.id == hormigoneraSelect);
    const nombreHormigonera = hormigoneraSeleccionada ? hormigoneraSeleccionada.nombre : ''; // Obtén el nombre de la hormigonera seleccionada

    setFormConsultaData({
      ...formConsultaData,
      ["hormigonera"]: nombreHormigonera,
    });

    //console.log(formConsultaData);
  };
  const handleBuscarFacturacionClick = async (event) => {
    event.preventDefault();

    try {
      //console.log(JSON.stringify(formConsultaData,null,2));
      const responseRequerimiento = await fetch(
        `https://api.vittamaq.cl/reporte_facturacion.php`,
        {
          method: "POST",
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json', 
          },
          body: JSON.stringify(formConsultaData),
        }
      );

      const dataRequerimiento = await responseRequerimiento.json();
      if (dataRequerimiento && dataRequerimiento[0].id_estado_pago) {
        setFacturaciones(dataRequerimiento);
      }

    } catch (error) {
      setFacturaciones([]);
    }
  }

  const exportToExcel = () => {
    const ahora = new Date();
    const fechaFormateada = formatearFecha(ahora);

    const arregloExportar = facturaciones.map(obj => {
      return {
        ["Maquinaria"]: obj.maquinaria,
        ["Cliente"]: obj.nombre_cliente,
        ["Obra"]: obj.nombre_obra,
        ["Folio Cotización"]: obj.folio_cotizacion,
        ["Fecha Estado Pago"]: obj.fecha_estado_pago,
        ["Fecha Facturación"]: obj.fecha_facturacion,
        ["Folio Kame"]: obj.folio_kame,
        ["Total Neto"]: Number(Math.round(obj.total_neto)).toLocaleString('es-ES', { useGrouping: true }).replace(/[\.,]/g, ''),
        ["Iva"]: Number(Math.round(obj.iva)).toLocaleString('es-ES', { useGrouping: true }).replace(/[\.,]/g, ''),
        ["Descuentos"]: Number(Math.round(obj.otros_descuentos)).toLocaleString('es-ES', { useGrouping: true }).replace(/[\.,]/g, ''),
        ["Total"]: Number(Math.round(obj.total)).toLocaleString('es-ES', { useGrouping: true }).replace(/[\.,]/g, ''),
      };
    });

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(arregloExportar);
    XLSX.utils.book_append_sheet(wb, ws, 'Reporte de venta');
    XLSX.writeFile(wb, 'Facturación ' + fechaFormateada + '.xlsx');
  };

  const formatearFecha = (fecha) => {
    const dia = String(fecha.getDate()).padStart(2, '0');
    const mes = String(fecha.getMonth() + 1).padStart(2, '0');
    const year = fecha.getFullYear();
    const hora = String(fecha.getHours()).padStart(2, '0');
    const minutos = String(fecha.getMinutes()).padStart(2, '0');

    return `${dia}-${mes}-${year} ${hora}_${minutos}`;
  };

  const formatearRut = (rut) => {
    const rutFormateado = `${rut.slice(0, 2)}.${rut.slice(2, 5)}.${rut.slice(5)}`;
    return rutFormateado;
  };

  /* Modals */
  const closeModal = () => {
    setModalOpen(false);
    setModalContent(null);
  };

  // Ver Cotización PDF
  const handleModalCotizacion = async (folioCotizacion) => {
    let cabeceraCotizacion = [];
    let detalleCotizacion = [];

    cabeceraCotizacion = await obtenerCabeceraCotizacion(folioCotizacion);
    detalleCotizacion = await obtenerDetalleCotizacion(folioCotizacion);

    setModalContent(<CotizacionModalContent cabecera_cotizacion={cabeceraCotizacion} detalle_cotizacion={detalleCotizacion} />);
    setModalOpen(true);
  };

  const obtenerCabeceraCotizacion = async (folio) => {
    try {
      const response = await fetch(
        `https://api.vittamaq.cl/cabecera_cotizacion.php?folio=${folio}`
      );
      const data = await response.json();
      return data && data[0]?.folio ? data : [];
    } catch (error) {
      console.error("Error al obtener la cabecera de la cotización:", error);
      return [];
    }
  };

  const obtenerDetalleCotizacion = async (folio) => {
    try {
      const response = await fetch(
        `https://api.vittamaq.cl/detalle_cotizacion.php?folio=${folio}`
      );
      const data = await response.json();
      return data && data[0]?.folio ? data : [];
    } catch (error) {
      console.error("Error al obtener el detalle de la cotización:", error);
      return [];
    }
  };

  function CotizacionModalContent ({ cabecera_cotizacion, detalle_cotizacion }) {
    const [pdfBase64, setPdfBase64] = useState('');

    useEffect(() => {
      const generatePDF = async () => {
        try {
          const existingPdfBytes = await fetch('https://api.vittamaq.cl/obtienePlantillaCotizacion.php').then((res) => res.arrayBuffer());
          const pdfDoc = await PDFDocument.load(existingPdfBytes);
      
          // Obtener el formulario del PDF
          const form = pdfDoc.getForm();
      
          // Cabecera Cotización
          form.getTextField('Folio').setText(cabecera_cotizacion[0].folio);
          form.getTextField('Nombre_Cliente').setText(cabecera_cotizacion[0].nombre_cliente);
          form.getTextField('Rut_Cliente').setText(formatearRut(cabecera_cotizacion[0].rut_cliente));
          form.getTextField('Razon_Social').setText(cabecera_cotizacion[0].razon_social);
          form.getTextField('Giro_Cliente').setText(cabecera_cotizacion[0].giro);
          form.getTextField('Direccion_Cliente').setText(cabecera_cotizacion[0].direccion);
          form.getTextField('Obra_Cliente').setText(cabecera_cotizacion[0].nombre_obra);
          form.getTextField('Direccion_Obra').setText(cabecera_cotizacion[0].direccion_obra + " (" + cabecera_cotizacion[0].comuna_obra + ")");

          form.getTextField('Fecha_Cotizacion').setText(cabecera_cotizacion[0].fecha_cotizacion);
          form.getTextField('Contacto').setText(cabecera_cotizacion[0].nombre_contacto);
          form.getTextField('Cargo_Contacto').setText(cabecera_cotizacion[0].cargo_contacto);
          form.getTextField('Telefono_Contacto').setText(cabecera_cotizacion[0].telefono_contacto);
          form.getTextField('Email_Contacto').setText(cabecera_cotizacion[0].email_contacto);
          form.getTextField('Observaciones').setText(cabecera_cotizacion[0].observaciones ? cabecera_cotizacion[0].observaciones : "");

          if (cabecera_cotizacion[0].id_maquinaria === 1) {
            form.getTextField('Equipo').setText(
              `${cabecera_cotizacion[0].nombre_maquinaria} (${cabecera_cotizacion[0].nombre_requerimiento})`
            );
          } else {
            form.getTextField('Equipo').setText(
              `${cabecera_cotizacion[0].nombre_maquinaria} - ${cabecera_cotizacion[0].equipo} (${cabecera_cotizacion[0].nombre_requerimiento})`
            );
          }

          let pmInserted = false;
          // Servicios Cotización
          detalle_cotizacion.forEach((detalle, index) => {
            form.getTextField(`Item_Orden_${index + 1}`).setText(detalle.item_orden.split('-')[1]);
            if(detalle.descripcion !== "") {
              form.getTextField(`Descripcion_${index + 1}`).setText(detalle.descripcion);
            } else {
              form.getTextField(`Descripcion_${index + 1}`).setText(detalle.nombre_maquinaria + " " + detalle.tipo_maquinaria + " " + detalle.tipo_arriendo);
            }
            form.getTextField(`Cantidad_Minima_${index + 1}`).setText(detalle.cantidad_minima);
            form.getTextField(`Unidad_Medida_${index + 1}`).setText(detalle.unidad_medida);

            if (detalle.id_maquinaria === 1) {
              form.getTextField(`Valor_Unitario_Neto_${index + 1}`).setText(detalle.valor_unitario_neto + " UF");
            } else if (detalle.id_maquinaria === 2) {
              form.getTextField(`Valor_Unitario_Neto_${index + 1}`).setText(String("$ " + parseInt(detalle.valor_unitario_neto).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.')));
            } else {
              form.getTextField(`Valor_Unitario_Neto_${index + 1}`).setText(String(parseFloat(detalle.valor_unitario_neto).toFixed(2) + " UF"));
            }

            if (detalle.id_maquinaria === 1 && !pmInserted) {
              form.getTextField(`Valor_Unitario_Neto_PM_${index + 1}`).setText(detalle.valor_unitario_neto_pm + " UF (PM)");
              pmInserted = true;
            }
          });

          const modifiedPdfBytes = await pdfDoc.save();
  
          const arrayBufferToBase64 = (buffer) => {
            let binary = '';
            const bytes = new Uint8Array(buffer);
            const len = bytes.byteLength;
            for (let i = 0; i < len; i++) {
              binary += String.fromCharCode(bytes[i]);
            }
            return window.btoa(binary);
          };
  
          const pdfBase64 = arrayBufferToBase64(modifiedPdfBytes);
          setPdfBase64(`data:application/pdf;base64,${pdfBase64}`);
        } catch (error) {
          console.error('Error generating PDF:', error);
        }
      };
  
      generatePDF();
    }, [cabecera_cotizacion, detalle_cotizacion]);

    return (
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="mb-0">Cotización</h4>
            <button onClick={closeModal} className="btn-close"></button>
          </div>
          <div className="modal-body pt-0">
            <iframe
              src={pdfBase64}
              title="Estado de Pago"
              style={{ width: "100%", height: "700px", border: "none" }}
            />
          </div>
        </div>
      </div>
    );
  }



  
  // Ver EEPP PDF
  const handleVerPDFep = async (tipoMaquinaria, idEstadoPago) => {
    let data = [];
    let reports = [];
    let detalle_equipos = [];
    
    switch (tipoMaquinaria) {
      case "BH":
        data = await obtenerEstadoPagoBH(idEstadoPago);
        reports = await obtenerReportsEstadoPagoBH(idEstadoPago);
        detalle_equipos = await obtenerDetalleServiciosEstadoPagoBH(reports);
        break;
      case "GH":
        data = await obtenerEstadoPagoGH(idEstadoPago);
        reports = await obtenerReportsEstadoPagoGH(idEstadoPago);
        detalle_equipos = await obtenerDetalleServiciosEstadoPagoGH(data[0].horas_trabajadas_valor, idEstadoPago, reports);
        break;
      default:
        data = await obtenerEstadoPagoGT(idEstadoPago);
        reports = await obtenerReportsEstadoPagoGT(idEstadoPago);
        detalle_equipos = await obtenerDetalleServiciosEstadoPagoGT(idEstadoPago, reports);
    }
    setModalContent(<VerPDFepModalContent datos={data} reports={reports} detalle_equipos={detalle_equipos} />);
    setModalOpen(true);
  };

  const obtenerEstadoPagoBH = async (idEstadoPago) => {
    try {
      const response = await fetch(
        `https://api.vittamaq.cl/estado_pago_bh.php?id_estado_pago_reporte=${idEstadoPago}`
      );
      const data = await response.json();
      return data && data[0]?.id_estado_pago_bh ? data : [];
    } catch (error) {
      console.error("Error al obtener estado de pago BH:", error);
      return [];
    }
  };

  const obtenerReportsEstadoPagoBH = async (idEstadoPago) => {
    try {
      const response = await fetch(
        `https://api.vittamaq.cl/estado_pago_reports_bh.php?id_estado_pago_bh=${idEstadoPago}`
      );
      const data = await response.json();
      return data && data[0]?.id_estado_pago_bh ? data : [];
    } catch (error) {
      console.error("Error al obtener estado de pago BH:", error);
      return [];
    }
  };

  const obtenerDetalleServiciosEstadoPagoBH = async (reports) => {
    try {
      // Agrupar por bomba
      const datosDescripcionAgrupados = reports.reduce((acc, current) => {
        const bomba = current.bomba;

        // Convertir sobre_estadia_hora a segundos para comparación
        const [hours, minutes, seconds] = current.sobre_estadia_hora.split(':').map(Number);
        const totalSeconds = hours * 3600 + minutes * 60 + seconds;
        const thresholdSeconds = 2 * 3600; // 02:00:00 en segundos

        // Si la bomba no está en el acumulador, inicializa su entrada
        if (!acc[bomba]) {
          acc[bomba] = { 
            ...current, 
            m3_bombeados: parseFloat(current.m3_bombeados), 
            valor_uf_total: parseFloat(current.valor_uf_total),
            aditivo: parseFloat(current.aditivo),
            sobre_estadia: 0, // Inicializar sobre_estadia,
            valor_total_bombeo_neto: parseFloat(current.valor_total_bombeo_neto)
          };
        } else {
          // Suma m3_bombeados y valor_uf_total
          acc[bomba].m3_bombeados += parseFloat(current.m3_bombeados);
          acc[bomba].valor_uf_total += parseFloat(current.valor_uf_total);
          acc[bomba].aditivo += parseFloat(current.aditivo);
          acc[bomba].valor_total_bombeo_neto += parseFloat(current.valor_total_bombeo_neto);
        }

        // Suma 1 a sobre_estadia si el tiempo es mayor a 02:00:00
        if (totalSeconds >= thresholdSeconds) {
          acc[bomba].sobre_estadia += 1;
        }

        return acc;
      }, {});

      // Formatea los resultados
      let detalle_equipos = [];
      const datosDescripcion = Object.values(datosDescripcionAgrupados).map(item => {
        const totalM3 = item.m3_bombeados.toFixed(2).replace('.', ',');
        const totalM3UF = item.valor_uf_total.toFixed(2).replace('.', ',');
        const totalM3Pesos = Number(item.valor_total_bombeo_neto).toLocaleString('es-ES', { useGrouping: true }).replace(/[\.,]/g, '');
        const sobreEstadia = Number(item.sobre_estadia * (item.valor_m3 * Number((item.valor_uf / 1000).toFixed(5)))).toLocaleString('es-ES', { useGrouping: true }).replace(/[\.,]/g, '');
        const aditivo = (item.aditivo * 5500).toLocaleString('es-ES', { useGrouping: true }).replace(/[\.,]/g, '');
        const trasladoBomba = (item.valor_traslado_bomba_uf * Number((item.valor_uf / 1000).toFixed(5))).toLocaleString('es-ES', { useGrouping: true }).replace(/[\.,]/g, '');
        const totalPesos = Number(item.valor_total_bombeo_neto).toLocaleString('es-ES', { useGrouping: true }).replace(/[\.,]/g, '');
        const fechaReport = new Date(item.fecha_report.split('-').reverse().join('-'));
      
        // Verificar si el equipo ya existe en detalleEquipos antes de agregarlo
        detalle_equipos = (() => {
          const existeEquipo = detalle_equipos.some(detalle => detalle.numero_equipo === item.numero);

          if (!existeEquipo) {
            return [
              ...detalle_equipos,
              {
                numero_equipo: item.bomba,
                total_m3_bombeados: totalM3,
                total_m3_valor_uf: totalM3UF,
                total_m3_valor_pesos: totalM3Pesos,
                total_aditivo: aditivo,
                total_sobre_estadia: sobreEstadia,
                total_traslado_bomba: trasladoBomba,
                total: totalPesos,
                fecha_report: fechaReport
              },
            ];
          }

          return detalle_equipos; // No añadir duplicados
        })();
        
        return detalle_equipos;
      });

      return detalle_equipos && detalle_equipos[0]?.numero_equipo ? detalle_equipos : [];
    } catch (error) {
      console.error("Error al obtener estado de pago GH:", error);
      return [];
    }
  };

  const obtenerEstadoPagoGH = async (idEstadoPago) => {
    try {
      const response = await fetch(
        `https://api.vittamaq.cl/estado_pago_gh.php?id_estado_pago_reporte=${idEstadoPago}`
      );
      const data = await response.json();
      return data && data[0]?.id_estado_pago_gh ? data : [];
    } catch (error) {
      console.error("Error al obtener estado de pago GH:", error);
      return [];
    }
  };

  const obtenerReportsEstadoPagoGH = async (idEstadoPago) => {
    try {
      const response = await fetch(
        `https://api.vittamaq.cl/estado_pago_reports_gh.php?id_estado_pago_gh=${idEstadoPago}`
      );
      const data = await response.json();
      return data && data[0]?.id_estado_pago_gh ? data : [];
    } catch (error) {
      console.error("Error al obtener estado de pago GH:", error);
      return [];
    }
  };

  const obtenerDetalleServiciosEstadoPagoGH = async (horas_trabajadas_valor, id_estado_pago_gh, reports) => {
    try {
      let serviciosGH = [];
      const response = await fetch(
        `https://api.vittamaq.cl/estado_pago_servicios_gh.php?id_estado_pago_gh=${id_estado_pago_gh}`
      );
      const data = await response.json();

      if (data && data[0].id_estado_pago_gh) {
        serviciosGH = data;
      }

      const convertirHorasADecimales = (horas) => {
        const [hh, mm, ss] = horas.split(':').map(Number);
        return hh + (mm / 60) + (ss / 3600); // Convierte minutos y segundos a fracciones de hora
      };

      const datosDescripcionAgrupados = reports.reduce((acc, current) => {
        const numero = current.numero;
      
        // Si el número de equipo no está en el acumulador, inicializa su entrada
        if (!acc[numero]) {
          acc[numero] = { 
            ...current, 
            total_horas: convertirHorasADecimales(current.total_horas), 
          };
        } else {
          // Aseguramos que acc[numero].total_horas es un número
          acc[numero].total_horas = parseFloat(acc[numero].total_horas) + convertirHorasADecimales(current.total_horas);
        }
      
        // Redondea a dos decimales
        acc[numero].total_horas = acc[numero].total_horas.toFixed(2);
      
        return acc;
      }, {});

      // Suma los valores de 'valor_unitario_neto'
      const totalValorUnitarioNeto = serviciosGH.reduce((sum, item) => {
        return sum + parseFloat(item.valor_unitario_neto);
      }, 0);

      // Formatea los resultados
      let detalle_equipos = [];
      const datosDescripcion = Object.values(datosDescripcionAgrupados).map(item => {
        const totalHorasTrabajadas = Number(item.total_horas).toFixed(2).replace('.', ',');
        const valorHorasTrabajadas = Number(Math.round(horas_trabajadas_valor) + Number(totalValorUnitarioNeto)).toLocaleString('es-ES', { useGrouping: true }).replace(/[\.,]/g, '');
        const totalValorHT = Number((item.total_horas < Number(8.00) ? Number(8).toFixed(2) : item.total_horas) * Math.round(horas_trabajadas_valor)).toLocaleString('es-ES', { useGrouping: true }).replace(/[\.,]/g, '');
        const fechaReport = new Date(item.fecha_report.split('-').reverse().join('-')); 

        const requiereTrasladoGrua = item.requiere_movilizacion_grua;
        const requiereTrasladoContrapeso = item.requiere_traslado_contrapeso;

        //Valores Traslado grúa y contrapeso
        let recargoTrasladoGruaValor = 0;
        let recargoTrasladoContrapesoValor = 0;

        //Suma traslado grúa
        if (requiereTrasladoGrua) {
          //console.log("Report: " + item.id_report_gh + ", N° Equipo: " + item.numero + ", Requiere TG: " + item.requiere_movilizacion_grua);
          recargoTrasladoGruaValor = serviciosGH
            .filter(servicio => servicio.id_servicio === 74)
            .reduce((acc, servicio) => {
              return acc + parseFloat(servicio.valor_total);
            }, 0);
        }

        //Suma traslado contrapeso
        if (requiereTrasladoContrapeso) {
          recargoTrasladoContrapesoValor = serviciosGH
            .filter(servicio => servicio.id_servicio === 73)
            .reduce((acc, servicio) => {
              return acc + parseFloat(servicio.valor_total);
            }, 0);
        }
        
        // Verificar si el equipo ya existe en detalleEquipos antes de agregarlo
        detalle_equipos = (() => {
          const existeEquipo = detalle_equipos.some(detalle => detalle.numero_equipo === item.numero);

          if (!existeEquipo) {
            return [
              ...detalle_equipos,
              {
                numero_equipo: item.numero,
                total_traslado_grua_valor: recargoTrasladoGruaValor,
                total_traslado_contrapeso_valor: recargoTrasladoContrapesoValor,
                total_horas_trabajadas: totalHorasTrabajadas,
                horas_trabajadas_valor: valorHorasTrabajadas,
                total_horas_trabajadas_valor: totalValorHT,
                fecha_report: fechaReport,
              },
            ];
          }

          return detalle_equipos; // No añadir duplicados
        })();
        
        return detalle_equipos;
      });

      return detalle_equipos && detalle_equipos[0]?.numero_equipo ? detalle_equipos : [];
    } catch (error) {
      console.error("Error al obtener estado de pago GH:", error);
      return [];
    }
  };

  const obtenerEstadoPagoGT = async (idEstadoPago) => {
    try {
      const response = await fetch(
        `https://api.vittamaq.cl/estado_pago_gt.php?id_estado_pago_reporte=${idEstadoPago}`
      );
      const data = await response.json();
      return data && data[0]?.id_estado_pago_gt ? data : [];
    } catch (error) {
      console.error("Error al obtener estado de pago GT:", error);
      return [];
    }
  };

  const obtenerReportsEstadoPagoGT = async (idEstadoPago) => {
    try {
      const response = await fetch(
        `https://api.vittamaq.cl/estado_pago_reports_gt.php?id_estado_pago_gt=${idEstadoPago}`
      );
      const data = await response.json();
      return data && data[0]?.id_estado_pago_gt ? data : [];
    } catch (error) {
      console.error("Error al obtener estado de pago GT:", error);
      return [];
    }
  };

  const obtenerDetalleServiciosEstadoPagoGT = async (id_estado_pago_gt, reports) => {
    try {
      let serviciosGT = [];
      const response = await fetch(
        `https://api.vittamaq.cl/estado_pago_servicios_gt.php?id_estado_pago_gt=${id_estado_pago_gt}`
      );
      const data = await response.json();

      if (data && data[0].id_estado_pago_gt) {
        serviciosGT = data;
      }

      // Agrupar por numero de equipo
      const convertirHorasADecimales = (horas) => {
        const [hh, mm, ss] = horas.split(':').map(Number);
        return hh + (mm / 60) + (ss / 3600); // Convierte minutos y segundos a fracciones de hora
      };

      const datosDescripcionAgrupados = reports.reduce((acc, current) => {
        const numero = current.numero;
      
        // Si el número de equipo no está en el acumulador, inicializa su entrada
        if (!acc[numero]) {
          acc[numero] = { 
            ...current, 
            total_horas: convertirHorasADecimales(current.total_horas), 
          };
        } else {
          // Aseguramos que acc[numero].total_horas es un número
          acc[numero].total_horas = parseFloat(acc[numero].total_horas) + convertirHorasADecimales(current.total_horas);
        }
      
        // Redondea a dos decimales
        acc[numero].total_horas = acc[numero].total_horas.toFixed(2);
      
        return acc;
      }, {});

      // Formatea los resultados
      let detalle_equipos = [];
      const datosDescripcion = Object.values(datosDescripcionAgrupados).map(item => {
        const totalHorasTrabajadas = Number(item.total_horas).toFixed(2).replace('.', ',');
        const valorTotal = Number(serviciosGT.find(servicio => servicio.id_servicio === 87)?.valor_total).toLocaleString('es-ES', { useGrouping: true }).replace(/[\.,]/g, '');
        const fechaReport = new Date(item.fecha_report.split('-').reverse().join('-'));   
        
        // Verificar si el equipo ya existe en detalleEquipos antes de agregarlo
        // Verificar si el equipo ya existe en detalleEquipos antes de agregarlo
        detalle_equipos = (() => {
          const existeEquipo = detalle_equipos.some(detalle => detalle.numero_equipo === item.numero);

          if (!existeEquipo) {
            return [
              ...detalle_equipos,
              {
                numero_equipo: item.numero,
                total_horas_trabajadas: totalHorasTrabajadas,
                total_horas_trabajadas_valor: valorTotal,
                fecha_report: fechaReport
              },
            ];
          }

          return detalle_equipos; // No añadir duplicados
        })();

        return detalle_equipos;
      });
    
      return detalle_equipos && detalle_equipos[0]?.numero_equipo ? detalle_equipos : [];
    } catch (error) {
      console.error("Error al obtener estado de pago GH:", error);
      return [];
    }
  };





  function VerPDFepModalContent ({ datos, reports, detalle_equipos }) {    
    const [pdfBase64, setPdfBase64] = useState('');

    useEffect(() => {
      const generatePDF = async () => {
        try {
          const existingPdfBytes = await fetch(
            `https://api.vittamaq.cl/obtienePlantillaEP.php?id_maquinaria=${datos[0].id_maquinaria}`
          ).then((res) => res.arrayBuffer());
  
          const pdfDoc = await PDFDocument.load(existingPdfBytes);
  
          // Obtener el formulario del PDF
          const form = pdfDoc.getForm();
  
          // Fecha Estado Pago
          let fechaActual = new Date();
          const formatDate = (date) => {
            const day = date.getDate().toString().padStart(2, '0');
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const year = date.getFullYear().toString();
            return `${day}-${month}-${year}`;
          };
          const fechaEP = formatDate(fechaActual);
  
          // Cabecera Estado Pago
          form.getTextField('Rut_Cliente').setText(formatearRut(datos[0].rut_cliente));
          form.getTextField('Razon_Social').setText(datos[0].razon_social.toUpperCase());
          form.getTextField('Giro_Cliente').setText(datos[0].giro.toUpperCase());
          form.getTextField('Direccion_Cliente').setText(datos[0].direccion.toUpperCase());
          form.getTextField('Obra_Cliente').setText(datos[0].nombre_obra.toUpperCase());
          form.getTextField('Direccion_Obra').setText(datos[0].direccion.toUpperCase() + " (" + datos[0].ciudad.toUpperCase() + ")");
          form.getTextField('Folio').setText(datos[0].folio.toUpperCase());
          form.getTextField('Fecha_EP').setText(fechaEP);
          form.getTextField('Contacto').setText(datos[0].nombre_contacto.toUpperCase());
          form.getTextField('Cargo_Contacto').setText(datos[0].cargo.toUpperCase());
          form.getTextField('Telefono_Contacto').setText(datos[0].telefono);
          form.getTextField('Email_Contacto').setText(datos[0].email);
  
          // Equipo Cotizado
          if (datos[0].id_maquinaria === 1) {
            const totalBombeoNeto = reports.reduce(
              (sum, item) => sum + parseFloat(item.valor_total_bombeo_neto),
              0
            );

            form.getTextField('Id_Estado_Pago').setText(datos[0].id_estado_pago_bh.toString());
            form.getTextField('Equipo').setText(`${datos[0].nombre_maquinaria.toUpperCase()} (${datos[0].nombre_requerimiento.toUpperCase()})`);
            form.getTextField('Detalle_Facturacion').setText(datos[0].razon_social.toUpperCase() + " - " + datos[0].nombre_obra.toUpperCase());
            form.getTextField('Aditivo').setText("VITTECH");
            form.getTextField('Aditivo_Valor_UF').setText(Number(datos[0].aditivo_uf).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.'));
            form.getTextField('Aditivo_Valor_Neto').setText("$ " + Number(datos[0].aditivo_valor).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.'));
            form.getTextField('Valor_Traslado_Bomba').setText("VALOR TRASLADO BOMBA");
            form.getTextField('Valor_Traslado_Bomba_UF').setText(Number(datos[0].valor_traslado_bomba_uf).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.'));
            form.getTextField('Valor_Traslado_Bomba_Neto').setText("$ " + Number(datos[0].valor_traslado_bomba).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.'));
            form.getTextField('Sobre_Estadia').setText("SOBRE ESTADIA");
            form.getTextField('Sobre_Estadia_UF').setText(Number(datos[0].sobre_estadia_uf).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.'));
            form.getTextField('Sobre_Estadia_Neto').setText("$ " + Number(datos[0].sobre_estadia_valor).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.'));
            form.getTextField('Valor_Total_Neto').setText("$ " + Number(totalBombeoNeto).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.'));
          } else if (datos[0].id_maquinaria === 2) {
            form.getTextField('Id_Estado_Pago').setText(datos[0].id_estado_pago_gh.toString());
            form.getTextField('Equipo').setText(`${datos[0].nombre_maquinaria.toUpperCase()} - ${datos[0].nombre_equipo.toUpperCase()} (${datos[0].nombre_requerimiento.toUpperCase()})`);
            form.getTextField('Detalle_Facturacion').setText(datos[0].razon_social.toUpperCase() + " - " + datos[0].nombre_obra.toUpperCase());

            //Totales Horas
            const sumTotalHoras = () => {
              const totalMinutes = reports.reduce((acc, item) => {
                const [hours, minutes] = item.total_horas.split(":").map(Number);
                return acc + hours * 60 + minutes;
              }, 0);
            
              // Convertir a formato HH:MM
              const totalHours = Math.floor(totalMinutes / 60);
              const totalMinutesRemainder = totalMinutes % 60;
              const totalHorasHHMM = `${String(totalHours).padStart(2, "0")}:${String(totalMinutesRemainder).padStart(2, "0")}`;
            
              // Convertir a decimal
              const totalHorasDecimal = (totalMinutes / 60).toFixed(2);
            
              return {
                totalHorasHHMM,
                totalHorasDecimal: parseFloat(totalHorasDecimal),
              };
            };

            const horasTotales = sumTotalHoras();
            form.getTextField('Horas_Totales_HHMM').setText(horasTotales.totalHorasHHMM.toString());
            form.getTextField('Horas_Totales_Decimal').setText(horasTotales.totalHorasDecimal.toString());
          } else {
            form.getTextField('Id_Estado_Pago').setText(datos[0].id_estado_pago_gt.toString());
            form.getTextField('Equipo').setText(`${datos[0].nombre_maquinaria.toUpperCase()} - ${datos[0].nombre_equipo.toUpperCase()} (${datos[0].nombre_requerimiento.toUpperCase()})`);
            form.getTextField('Detalle_Facturacion').setText(datos[0].razon_social.toUpperCase() + " - " + datos[0].nombre_obra.toUpperCase() + " - " + datos[0].nombre_equipo.toUpperCase());
            form.getTextField('Horas_Incluidas_Operador').setText(datos[0].horas_incluidas_operador.toString());
            form.getTextField('Horas_Adicionales_Operador').setText(datos[0].horas_adicionales_operador.toString());
            form.getTextField('Valor_Hora_Adicional_Operador').setText(datos[0].valor_hora_adicional_uf_operador.toString());
            form.getTextField('Valor_Hora_Adicional_SD_Operador').setText(datos[0].horas_adicionales_domingos_festivos_operador.toString());
            form.getTextField('Valor_Hora_Adicional_UF_Operador').setText(Number(datos[0].valor_hora_adicional_domingos_festivos_uf_operador).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.'));
            form.getTextField('Valor_UF_Fecha_Facturacion').setText("$ " + Number(datos[0].valor_uf_fecha_facturacion).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.'));
            form.getTextField('Valor_Total_HH_Adicionales_Operador').setText("$ " + Number(datos[0].valor_total_horas_adicionales_operador).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.'));
            form.getTextField('Horas_Incluidas_Grua').setText(datos[0].horas_incluidas_grua.toString());
            form.getTextField('Horas_Adicionales_Grua').setText(datos[0].horas_adicionales_grua.toString());
            form.getTextField('Valor_Hora_Adicional_Grua').setText(datos[0].valor_hora_adicional_uf_grua.toString());
            form.getTextField('Valor_Total_HH_Adicionales_Grua').setText("$ " + Number(datos[0].valor_total_horas_adicionales_grua).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.'));

            //Totales Horas
            const sumTotalHoras = () => {
              const totalMinutes = reports.reduce((acc, item) => {
                const [hours, minutes] = item.total_horas.split(":").map(Number);
                return acc + hours * 60 + minutes;
              }, 0);
            
              // Convertir a formato HH:MM
              const totalHours = Math.floor(totalMinutes / 60);
              const totalMinutesRemainder = totalMinutes % 60;
              const totalHorasHHMM = `${String(totalHours).padStart(2, "0")}:${String(totalMinutesRemainder).padStart(2, "0")}`;
            
              // Convertir a decimal
              const totalHorasDecimal = (totalMinutes / 60).toFixed(2);
            
              return {
                totalHorasHHMM,
                totalHorasDecimal: parseFloat(totalHorasDecimal),
              };
            };

            const horasTotales = sumTotalHoras();
            form.getTextField('Horas_Totales_HHMM').setText(horasTotales.totalHorasHHMM.toString());
            form.getTextField('Horas_Totales_Decimal').setText(horasTotales.totalHorasDecimal.toString());

            // Fecha y UF Hoy
            const obtenerFechaHoy = () => {
              const hoy = new Date();
              const opciones = { day: 'numeric', month: 'long', year: 'numeric' };
              const fechaFormateada = hoy.toLocaleDateString('es-ES', opciones);
              return fechaFormateada;
            };
            
            const fechaHoy = obtenerFechaHoy();
            form.getTextField('Fecha_Hoy').setText("VALOR UF AL " + fechaHoy.toUpperCase());
            form.getTextField('UF_Dia').setText("$ " + indicadorUF.toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.'));

            // Valor total horas adicionales UF
            const HHAdicionalesOperadorUF = (Number(datos[0].valor_total_horas_adicionales_operador) / indicadorUF).toFixed(2).replace('.', ',');
            const HHAdicionalesGruaUF = (Number(datos[0].valor_total_horas_adicionales_grua) / indicadorUF).toFixed(2).replace('.', ',');

            form.getTextField('Valor_Total_HH_Adicionales_UF_Operador').setText(HHAdicionalesOperadorUF.toString());
            form.getTextField('Valor_Total_HH_Adicionales_UF_Grua').setText(HHAdicionalesGruaUF.toString());

            // Días y horas del mes
            function obtenerDiasDelMes(fecha) {
              const [dia, mes, anio] = fecha.split("-").map(Number);
          
              const ultimoDia = new Date(anio, mes, 0).getDate();
          
              return ultimoDia;
            }

            const diasDelMes = obtenerDiasDelMes(datos[0].fecha_estado_pago);
            form.getTextField('Dias_Trabajar_Mes').setText(diasDelMes.toString());

            function obtenerHorasSemanales() {
              const diasPorSemana = 7;
              const horasPorDia = 24;
              return diasPorSemana * horasPorDia;
            }
            
            const horasSemanales = obtenerHorasSemanales();
            form.getTextField('Horas_Trabajar_Mes').setText(horasSemanales.toString());

            // Valores UF Operador y Grúa
            form.getTextField('Valor_UF_Operador').setText(Number(datos[0].valor_operador_uf).toString());
            form.getTextField('Valor_UF_Grua').setText(Number(datos[0].valor_grua_uf).toString());
          }

          //console.log(JSON.stringify(reports,null,2))
          // Equipos Utilizados
          detalle_equipos.forEach((detalle, index) => {
            if (datos[0].id_maquinaria === 1) {
              form.getTextField(`Item_${index + 1}`).setText("M3 BOMBEADOS - BH # " + detalle.numero_equipo);
              form.getTextField(`Valor_UF_${index + 1}`).setText(detalle.total_m3_valor_uf);
              form.getTextField(`Valor_Neto_${index + 1}`).setText("$ " + Number(detalle.total).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.'));
            } else if (datos[0].id_maquinaria === 2) {
              form.getTextField(`Item_${index + 1}`).setText("HORAS TRABAJADAS - GH # " + detalle.numero_equipo);
              form.getTextField(`Total_Horas_${index + 1}`).setText(detalle.total_horas_trabajadas);
              form.getTextField(`Valor_Hora_${index + 1}`).setText("$ " + Number(detalle.total_horas_trabajadas_valor).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.'));
              form.getTextField(`Valor_Total_${index + 1}`).setText("$ " + Number(detalle.horas_trabajadas_valor).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.'));
            } else {
              // Valor total horas adicionales UF
              const valorHHTotalesUF = (Number(detalle.total_horas_trabajadas_valor) / indicadorUF).toFixed(2).replace('.', ',');

              form.getTextField(`Item_${index + 1}`).setText("HORAS TRABAJADAS - GT # " + detalle.numero_equipo);
              form.getTextField(`Valor_Hora_UF_${index + 1}`).setText(valorHHTotalesUF.toString());
              form.getTextField(`Valor_Hora_${index + 1}`).setText("$ " + Number(detalle.total_horas_trabajadas_valor).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.'));
              form.getTextField(`Mes_LV_${index + 1}`).setText(new Intl.DateTimeFormat('es', { month: 'long' }).format(new Date(detalle.fecha_report)).toUpperCase() + " (Lunes a Viernes)");
            }
            form.getTextField(`Mes_${index + 1}`).setText(new Intl.DateTimeFormat('es', { month: 'long' }).format(new Date(detalle.fecha_report)).toUpperCase());
            form.getTextField(`Mes_Year_${index + 1}`).setText(new Intl.DateTimeFormat('es', { month: 'long' }).format(new Date(detalle.fecha_report)).toUpperCase() + " - " + new Intl.DateTimeFormat('es', { year: 'numeric' }).format(new Date(detalle.fecha_report)));
          });
  
          // Totales Estado de Pago
          form.getTextField('Total_Neto').setText("$ " + Number(datos[0].total_neto).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.'));
          form.getTextField('IVA').setText("$ " + Number(datos[0].iva).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.'));
          form.getTextField('Descuentos').setText("DESCUENTOS");
          form.getTextField('Descuentos_Valor').setText("$ " + Number(datos[0].otros_descuentos).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.'));
          form.getTextField('Total').setText("$ " + Number(datos[0].total).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.'));

          // Reports Estado de Pago
          reports.forEach((report, index) => {
            form.getTextField(`Dia_Report_${index + 1}`).setText(report.dia.toUpperCase());
            if (datos[0].id_maquinaria === 1) {
              form.getTextField(`Fecha_Report_${index + 1}`).setText(report.fecha_report);
              form.getTextField(`Numero_Report_${index + 1}`).setText(report.id_report_bh.toString());
              form.getTextField(`Equipo_Report_${index + 1}`).setText(report.bomba.toString());
              form.getTextField(`M3_Reales_Report_${index + 1}`).setText(report.m3_bombeados_reales.toString().replace('.', ','));
              form.getTextField(`M3_Bombeados_Report_${index + 1}`).setText(report.m3_bombeados.replace('.', ','));
              form.getTextField(`Valor_M3_Report_${index + 1}`).setText(report.valor_m3.toString().replace('.', ','));
              form.getTextField(`Valor_UF_Total_Report_${index + 1}`).setText(report.valor_uf_total.toString().replace('.', ','));
              form.getTextField(`Valor_UF_Report_${index + 1}`).setText(Number(report.valor_uf).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.'));
              form.getTextField(`Valor_Total_Neto_Report_${index + 1}`).setText(Number(report.valor_total_bombeo_neto).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.'));
              form.getTextField(`Lugar_Faena_Report_${index + 1}`).setText(datos[0].nombre_obra.toUpperCase());
            } else if (datos[0].id_maquinaria === 2) {
              form.getTextField(`Fecha_Report_${index + 1}`).setText(report.fecha_report);
              form.getTextField(`Numero_Report_${index + 1}`).setText(report.id_report_gh.toString());
              form.getTextField(`Equipo_Report_${index + 1}`).setText(report.numero.toString());
              form.getTextField(`Hora_Inicio_Manana_Report_${index + 1}`).setText(report.hora_inicio_manana.slice(0, 5).toString());
              form.getTextField(`Hora_Termino_Manana_Report_${index + 1}`).setText(report.hora_termino_manana.slice(0, 5).toString());
              form.getTextField(`Total_Horas_Manana_Report_${index + 1}`).setText(report.total_horas_manana.slice(0, 5).toString());
              form.getTextField(`Hora_Inicio_Tarde_Report_${index + 1}`).setText(report.hora_inicio_tarde.slice(0, 5).toString());
              form.getTextField(`Hora_Termino_Tarde_Report_${index + 1}`).setText(report.hora_termino_tarde.slice(0, 5).toString());
              form.getTextField(`Total_Horas_Tarde_Report_${index + 1}`).setText(report.total_horas_tarde.slice(0, 5).toString());
              form.getTextField(`Total_Horas_Report_${index + 1}`).setText(report.total_horas.slice(0, 5).toString());
              form.getTextField(`Lugar_Faena_Report_${index + 1}`).setText(datos[0].nombre_obra.toUpperCase());
            } else {
              form.getTextField(`Mes_Report_${index + 1}`).setText(report.mes.toUpperCase());
              form.getTextField(`Numero_Report_${index + 1}`).setText(report.id_report_gt.toString());
              form.getTextField(`Hora_Inicio_Manana_Report_${index + 1}`).setText(report.hora_inicio_manana.slice(0, 5).toString());
              form.getTextField(`Hora_Termino_Manana_Report_${index + 1}`).setText(report.hora_termino_manana.slice(0, 5).toString());
              form.getTextField(`Total_Horas_Manana_Report_${index + 1}`).setText(report.total_horas_manana.slice(0, 5).toString());
              form.getTextField(`Hora_Inicio_Tarde_Report_${index + 1}`).setText(report.hora_inicio_tarde.slice(0, 5).toString());
              form.getTextField(`Hora_Termino_Tarde_Report_${index + 1}`).setText(report.hora_termino_tarde.slice(0, 5).toString());
              form.getTextField(`Total_Horas_Tarde_Report_${index + 1}`).setText(report.total_horas_tarde.slice(0, 5).toString());
              form.getTextField(`Total_Horas_Report_${index + 1}`).setText(report.total_horas.slice(0, 5).toString());
              form.getTextField(`Total_Horas_Form_Report_${index + 1}`).setText(Number(parseInt(report.total_horas.split(':')[0]) + parseInt(report.total_horas.split(':')[1]) / 60).toString());
            }
          });

          const modifiedPdfBytes = await pdfDoc.save();
  
          const arrayBufferToBase64 = (buffer) => {
            let binary = '';
            const bytes = new Uint8Array(buffer);
            const len = bytes.byteLength;
            for (let i = 0; i < len; i++) {
              binary += String.fromCharCode(bytes[i]);
            }
            return window.btoa(binary);
          };
  
          const pdfBase64 = arrayBufferToBase64(modifiedPdfBytes);
          setPdfBase64(`data:application/pdf;base64,${pdfBase64}`);
        } catch (error) {
          console.error('Error generating PDF:', error);
        }
      };
  
      generatePDF();
    }, [datos, reports, detalle_equipos]);

    return (
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="mb-0">Estado de Pago</h4>
            <button onClick={closeModal} className="btn-close"></button>
          </div>
          <div className="modal-body pt-0">
            <iframe
              src={pdfBase64}
              title="Estado de Pago"
              style={{ width: "100%", height: "700px", border: "none" }}
            />
          </div>
        </div>
      </div>
    );
  }


  return (
    <div className="d-flex" style={styles.mainContainer}>
      <SideMenu dataLogin={dataLogin} />
      <div className="w-100">
        <Header dataLogin={dataLogin} />
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <div className="d-flex gap-md-2 mb-3">
                <div>
                  <label className="form-label">Maquinaria</label>
                  <select className="form-select" name="id_tipo_maquinaria" value={formConsultaData.id_maquinaria} onChange={(e) => handleMaquinariaSelect(e.target.value)}>
                    <option value="0">Todos</option>
                    {maquinariasOptions.map((maquinaria, index) => (
                      <option key={index} value={maquinaria.id_maquinaria}>{maquinaria.maquinaria}</option>
                    ))}
                  </select>
                </div>
                <div>
                  <label className="form-label">Equipo</label>
                  {equiposOptions.length > 0 ? (
                  <select className="form-select" name="equipos" value={formConsultaData.equipos} onChange={handleFormConsultaData}>
                    <option value="0">Todos</option>
                    {equiposOptions.map((equipo) => (
                      <option key={equipo.id_equipo} value={equipo.id_equipo}>{equipo.marca + " " + equipo.modelo}</option>
                    ))}
                  </select>
                  ) : (
                    <select className="form-select" name="equipos">
                      <option value="0">Todos</option>
                    </select>
                    )}
                </div>
                <div>
                  <label className="form-label">Hormigonera</label>
                  <select className="form-select" name="hormigoneras" value={hormigoneraSelect} onChange={(e) => handleHormigoneraSelect(e.target.value)}>
                    <option value="0">Todos</option>
                    {/* {hormigonerasOptions.map((hormigonera) => (
                      <option key={hormigonera.id} value={hormigonera.id}>{hormigonera.nombre_homigonera}</option>
                    ))} */}
                    {hormigonerasOptions.map((hormigonera, index) => (
                      <option key={index+1} value={index+1}>{hormigonera.nombre_homigonera}</option>
                    ))}
                  </select>
                </div>
                <div>
                  <label className="form-label">Cliente</label>
                  <select className="form-select" name="cliente" value={clienteSelected} onChange={(e) => handleClienteSelect(e.target.value)}>
                    <option value="0">Todos</option>
                    {clientesOptions.map((cliente) => (
                      <option key={cliente.id_cliente} value={cliente.id_cliente}>{cliente.nombre_cliente}</option>
                    ))}
                  </select>
                </div>
                <div>
                  <label className="form-label">Obra</label>
                  {obras.length > 0 ? (
                    <select className="form-select" name="id_obra" value={formConsultaData.id_obra} onChange={handleFormConsultaData}>
                      <option value="0">Todos</option>
                      {obras.map((obra) => (
                        <option key={obra.id_obra} value={obra.id_obra}>{obra.nombre}</option>
                      ))}
                    </select>
                  ) : (
                    <select className="form-select" name="id_obra">
                      <option value="0">Todos</option>
                    </select>
                  )}
                </div>  
                <div>
                  <label className="form-label">Fecha Inicio</label>
                  <input
                    className="form-control"
                    type="date"
                    name="fecha_inicio"
                    value={formConsultaData.fecha_inicio}
                    onChange={handleFormConsultaData}
                  />
                </div>
                <div className="">
                  <label className="form-label">Fecha Fin</label>
                  <input
                    className="form-control"
                    type="date"
                    name="fecha_fin"
                    value={formConsultaData.fecha_fin}
                    onChange={handleFormConsultaData}
                  />
                </div>     
                <div>
                  <label className="form-label d-block">&nbsp; </label>
                  <button className="btn btn-yellow" onClick={handleBuscarFacturacionClick}>
                    <i className="bi bi-search"></i> Buscar
                  </button>
                </div>   
              </div>
              <div className="card">
                <div className="card-header d-flex justify-content-between align-items-center border-bottom-0 p-3 bg-white">
                  <h4>Reporte Facturación</h4>
                  <div className="d-flex gap-md-2">
                    <div className="input-group w-auto">
                      <span className="input-group-text" id="search-folio">
                        <i className="bi bi-search"></i>
                      </span>
                      <input type="text" className="form-control" placeholder="Obra..." aria-describedby="search-folio" onChange={manejarCambioFiltro}/>
                    </div>
                    {facturaciones.length > 0 ? (
                      <button className="btn btn-success" onClick={exportToExcel}><i className="bi bi-download"></i> Exportar Excel</button>
                    ) : (
                      <button className="btn btn-success" disabled><i className="bi bi-download"></i> Exportar Excel</button>
                    )}
                  </div>
                </div>
                <div className="card-body card-body-filter border-bottom-radius p-0">
                  <div className="table-responsive  mytable-container">
                    <table className="table table-hover mb-0">
                      <thead>
                        <tr className="table-active border-top border-bottom">
                          <th scope="col" className="ps-3">Maquinaria</th>
                          <th scope="col">Cliente</th>
                          <th scope="col">Obra</th>
                          <th scope="col">Folio Cotización</th>
                          <th scope="col">ID Estado Pago</th>
                          <th scope="col">Fecha Estado Pago</th>
                          <th scope="col">Fecha Facturación</th>
                          <th scope="col">Folio Kame</th>
                          <th scope="col">Total Neto</th>
                          <th scope="col">Iva</th>
                          <th scope="col">Otros Descuentos</th>
                          <th scope="col" className="pe-3">Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        {facturacionesFiltradas.length > 0 ? (
                          facturacionesFiltradas.map((facturacion, index) => (
                            <tr key={index}>
                              <td className="ps-3">{facturacion.maquinaria}</td>
                              <td>{facturacion.nombre_cliente}</td>
                              <td>{facturacion.nombre_obra}</td>
                              <td>
                                <button className="btn btn-link p-0" onClick={() => handleModalCotizacion(facturacion.folio_cotizacion)}>{facturacion.folio_cotizacion}</button>
                              </td>
                              <td>
                                <button className="btn btn-link p-0" onClick={(e) => handleVerPDFep(facturacion.id_estado_pago.split("-")[0], facturacion.id_estado_pago.split("-")[1])}>{facturacion.id_estado_pago}</button>
                              </td>
                              <td>
                                {facturacion.fecha_estado_pago}
                              </td>
                              <td>{facturacion.fecha_facturacion}</td>
                              <td>{facturacion.folio_kame}</td>
                              <td>{Number(Math.round(facturacion.total_neto)).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.')}</td>
                              <td>{Number(Math.round(facturacion.iva)).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.')}</td>
                              <td>{Number(Math.round(facturacion.otros_descuentos)).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.')}</td>
                              <td className="pe-3"><span className="text-orange fw-bold">{Number(Math.round(facturacion.total)).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.')}</span></td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td className="text-center" colSpan={12}>No se encontró facturación</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        style={modalStyles}
        contentLabel="Modal"
        className="modal"
      >
        {modalContent}
      </Modal>
    </div>
  );
}

const modalStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
  },
};

const styles = {
  mainContainer: {
    height: "100vh",
  },
};

export default ReporteVenta;
